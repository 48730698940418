import { Doughnut } from "./components/doughnut/Doughnut";
import { MiniDoughnut } from "./components/mini-doighnut/MiniDoughnut";
import { PaMListForward } from "./components/PaMList/PaMListForward";
import { PaMListReverse } from "./components/PaMList/PaMListReverse";
import "./plusAndMinus.css"


export function PlusAndMinus(){
  let width = window.innerWidth;
  return (
    <div>
      <div className="welcomePage__Header">
        Преимущества сервиса
      </div>

      <div className="plusAndMinus__Wrapper  element-animation">
        <PaMListForward />
        {width > 1500 ? <Doughnut /> : <MiniDoughnut />}
        <PaMListReverse />
      </div>
    </div>

  )
}
