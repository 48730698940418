import './style.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {downloadFile} from "../../../services/FileService";
export function MobileCards() {

    return (
        <div className="mobile_wrapper bg-[#3D6BB5]" onClick={() => downloadFile()}>
            <p className="mobile_name">Шаблон для заполнения</p>
            <FontAwesomeIcon className="mobile_icon" icon={faDownload} bounce/>
        </div>
    )
}
