export function Plus({text, isReverse, top = '-25px', left='-220px'}) {
    return (
        <div className="whatIsStatbim__Diagram__plus__wrapper" 
        style={{flexDirection: isReverse ? 'row-reverse' : 'row',
                'top': `${top}`,
                'left': `${left}`,
        }}
        
        >
            <div className="plus-wrapper-text">{text}</div>
            <div className="plus-wrapper-circle">
            <div className="plus-wrapper-circle-plus">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0781 12.9583C25.0781 14.5 23.8229 15.75 22.276 15.75H15.7239V22.25C15.7239 23.7916 14.4687 25.0416 12.9218 25.0416C11.3698 25.0416 10.1145 23.7916 10.1145 22.25V15.75H3.56767C2.01558 15.75 0.760376 14.5 0.760376 12.9583C0.760376 11.4219 2.01558 10.1719 3.56767 10.1719H10.1145V3.66665C10.1145 2.13019 11.3698 0.880188 12.9218 0.880188C14.4687 0.880188 15.7239 2.13019 15.7239 3.66665V10.1719H22.276C23.8229 10.1719 25.0781 11.4219 25.0781 12.9583Z" fill="#EFF0EF"/>
                </svg>
            </div>
            </div>
        </div>
    )
}