import {useEffect} from "react";

export const useOtherPagesNavBar = () => {
    useEffect(() => {
        document.querySelector('.nav').style.backgroundColor = "var(--nav-bg)";
        document.querySelector('.nav').style.position = "inherit";
        return () => {
            document.querySelector('.nav').style.backgroundColor = "var(--transparent)";
            document.querySelector('.nav').style.position = "fixed";
        };
    }, [])
}
