import "./style.css"
import tg from "../../img/icons/tg.png";
export function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <ul className="social">
                        <li className="social__item"><a rel="noreferrer"  target="_blank" href="https://t.me/Stat_bim_bot"><img style={{width: "38px"}} src={tg} alt="Link"/></a></li>
                    </ul>
                    {/*<div className="copyright">*/}
                    {/*    <p>© 2023 github.com/molch4nov</p>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}
