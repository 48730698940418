import './block-number.css'

export function BlockNumber({number, color}) {
    let is2 = number % 2 === 0;

    return (
        <div style={{position: 'relative', marginBottom: '30px', marginLeft: is2 ? '150px' : 0}}>
            <div className='block-number-wrapper'>
                <div className='block-number-main'  style={{backgroundColor: color}}>
                    <div className="block-number">
                        {number}
                    </div>
                </div>

            </div>
            {number !== '5' &&
                <div className='block-number-dot-stone' style={{left: is2 ? '-20px' : '135px'}} />
            }
        </div> 
    )
}