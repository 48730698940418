export function PaMListForward() {
    const plusesList = [
        'ДОСТОВЕРНОСТЬ', 
        'НАГЛЯДНОСТЬ', 
        'ПРОСТОТА', 
        'СКОРОСТЬ', 
        'СНИЖЕНИЕ ИЗДЕРЖЕК', 
        'УВЕЛИЧЕНИЕ ПРИБЫЛИ ПРИ МЕНЬШИХ ЗАТРАТАХ',
        'ТОЧНОСТЬ НА КАЖДОМ ШАГЕ',
        'НЕЗНАЧИТЕЛЬНОСТЬ ВЛОЖЕНИЙ ПО СРАВНЕНИЮ С ПРИБЫЛЬЮ ОТ ИСПОЛЬЗОВАНИЯ STATBIM',
    ];

    return (
        <div className="plusAndMinus__list">
          {plusesList.map((value) => (
            <div className="plusAndMinus__list__wrapper">
              <div className="plusAndMinus__list__wrapper__text">
                   {value}
              </div>
              <div className="plusAndMinus__list__wrapper__icon">
                <div class="wrap">
                  <div id="check-part-1" class="check-sign"></div>
                  <div id="check-part-2" class="check-sign"></div>
                </div>
              </div>
          </div>
          ))}
        </div>
    )
}