import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {Login} from "./pages/Login";
import {Footer} from "./components/footer/Footer";
import "./styles/main.css";
import { useEffect, useState} from "react";
import {Register} from "./pages/Register";
import {Agreement} from "./pages/Agreement";
import {Propertys} from "./pages/Propertys";
import {Projects} from "./pages/Projects";
import {Analytics} from "./pages/Analytics";
import {checkToken} from "./services/AuthService";
import {Contacts} from "./pages/Contacts";
import {WelcomePage} from "./pages/welcomePage/WelcomePage";
import { SimpleNavbar } from "./components/navbar-simple/SimpleNavbar";


function App() {
    const [isAuth, setIsAuth] = useState(false);
    const [first, setFirst] = useState(false);
    useEffect(() => {
      if(!first){
          if(localStorage.getItem('token')){
            setIsAuth(true);
            setFirst(true)
            localStorage.setItem('auth', 'true');
          }
      }

  }, [first, isAuth])

  const [isActive, setIsActive] = useState(false);

    return (
      <div className="App">
        <Router>
          <SimpleNavbar isAuth={isAuth} setIsAuth={setIsAuth} />
          <Routes>
              <Route path="/" element={<WelcomePage isAuth={isAuth} setIsAuth={setIsAuth} />} />
              <Route path="/login" element={<Login isAuth={isAuth} setIsAuth={setIsAuth}/>} />
              <Route path="/register" element={<Register isAuth={isAuth} setIsAuth={setIsAuth}/>} />
              <Route path="/contacts" element={<Contacts/>} />
              <Route path="/agreement" element={<Agreement />} />
              <Route path="/property" element={<Projects/>} />
              <Route path="/property/armatura" element={<Propertys/>} />
              <Route path="/analytics" element={checkToken() ? <Analytics isAuth={isAuth} setIsAuth={setIsAuth} /> : <Navigate to="/login" />} />
          </Routes>
          <Footer />
        </Router>
      </div>
  );
}

export default App;
