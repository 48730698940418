import "./object.css"

export function Object({name}) {
  return (
    <div className="object__Wrapper">

      <div className="object__icon">

        <div className="object__icon__wrapper">

          <div className="whyConsumerNeed__TextBlock__ObjectCircle__BlackCircle">
            <div className="object__TextBlock__ObjectCircle__BlackCircle__circle" />
          </div>

          <div className="object__icon__svg">
            <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9218 44.7189C0.598877 44.7189 0.598877 41.2554 0.598877 39.9481V37.6981C0.604085 34.1616 2.677 30.9481 5.90617 29.4845L13.2499 26.1564C14.0208 25.8075 14.6822 25.2606 15.1718 24.5731C15.5989 23.9637 15.6353 23.1616 15.2551 22.521C13.5989 19.646 12.7291 16.3908 12.7395 13.0731C12.7395 6.68766 15.8853 0.239746 22.9218 0.239746C29.953 0.239746 33.1041 6.68766 33.1041 13.0731C33.1145 16.3908 32.2447 19.6512 30.5833 22.5262C30.203 23.1668 30.2395 23.9689 30.6718 24.5783C31.1614 25.2658 31.8228 25.8127 32.5885 26.1616L39.9374 29.4845C43.1666 30.9533 45.2395 34.1616 45.2395 37.6981V39.9481C45.2395 41.2554 45.2395 44.7189 22.9218 44.7189ZM4.78638 38.9741C6.7395 39.6252 12.9687 40.547 22.9218 40.547C32.8749 40.547 39.1041 39.6252 41.052 38.9741V37.6981C41.052 35.797 39.9374 34.0679 38.1978 33.2814L30.8541 29.9533C29.4062 29.297 28.1614 28.2658 27.2447 26.9689C25.8489 25.0054 25.7551 22.4064 27.0103 20.3491C28.2655 18.1304 28.9218 15.6252 28.9218 13.0731C28.9218 11.6304 28.6301 4.41162 22.9218 4.41162C17.2083 4.41162 16.9218 11.6304 16.9218 13.0731C16.9166 15.6252 17.5728 18.1252 18.828 20.3491C20.078 22.4064 19.9895 25.0054 18.5937 26.9689C17.6822 28.2658 16.4322 29.297 14.9843 29.9533L7.64054 33.2814C5.90096 34.0679 4.79158 35.797 4.78638 37.6981V38.9741Z" fill="#EFF0EF"/>
            </svg>
          </div>

        </div>

      </div>

      <div className="object__name">
        {name}
      </div>

    </div>
  )
}
