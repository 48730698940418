import { downloadFile} from "../../../services/FileService";
import "./style.css"

export function AnalyticsCard({src, title, text, type}) {
    return (
        <>
            <div className="card_wrapper" onClick={() => {
                if (type === "download") {
                    downloadFile()
                }
            }}>
                <div className="rounded-2xl w-80 h-96 bg-[#3D6BB5]">
                    <div className="py-6 px-6">
                        <img src={src} alt="" className="m-auto"/>
                    </div>
                    <div className="font-bold text-center text-white text-2xl">
                        {title}
                    </div>
                    <div className="font-semibold text-center text-white text-lg py-3 px-10">
                        {text}
                    </div>
                </div>
            </div> 
        </>
    )
}
