export class ColumnsService {
    transformingData(row1, row2, row3) {
    let transfromed1, transfromed2, transfromed3;
    if(row1 !== undefined && row2 !== undefined && row3 !== undefined){
      transfromed1 = this.transformData1(row1);
      transfromed2 = this.transformData2(row2);
      transfromed3 = this.transformData2(row3);
    }
    return [transfromed1, transfromed2, transfromed3]
  }

  transformData1(data) {
    let transformedData = [];
    for(let i = 0; i < data[0].length; i++) {
      let obj = {
        id: i + 1,
        feature: data[0][i],
        firstName: data[1][i],
        secondName: data[2][i],
        thirdName: data[3][i],
      };
      transformedData.push(obj);
    }
    return transformedData;
  }

  transformData2(data) {
    let transformedData = [];
    for(let i = 0; i < data[0].length; i++) {
      let obj = {
        id: i + 1,
        feature: data[0][i],
        firstName: data[1][i],
        secondName: data[2][i],
      };
      transformedData.push(obj);
    }
    return transformedData;
  }

  desktopColumns() {
    const columns1 = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'feature',
        headerName: 'Характеристика',
        width: 300,
        editable: false,
        flex: 1,
      },
      {
        field: 'firstName',
        headerName: 'Критерий Пирсона',
        width: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'secondName',
        headerName: 'Критерий Колмогорова-Смирнова',
        width: 110,
        editable: false,
        flex: 1,
      },
      {
        field: 'thirdName',
        headerName: 'Критерий Шапиро-Уилка ',
        width: 110,
        editable: false,
        flex: 1,
      },
    ];

    const columns2 = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'feature',
        headerName: 'Характеристика',
        width: 300,
        editable: false,
        flex: 1,
      },
      {
        field: 'firstName',
        headerName: 'Оптимальное теоритическое распределение',
        width: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'secondName',
        headerName: 'Значение статистики Колмагорова-Смирнова (норм/опт)',
        width: 110,
        editable: false,
        flex: 1,
      }
    ];

    const columns3 = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'feature',
        headerName: 'Характеристика',
        width: 300,
        editable: false,
        flex: 1,
      },
      {
        field: 'firstName',
        headerName: 'Значение',
        width: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'secondName',
        headerName: 'Обеспеченность',
        width: 110,
        editable: false,
        flex: 1,
      }
    ];

    return [columns1, columns2, columns3]
  }

  mobileColumns() {
    const columns1 = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'feature',
        headerName: 'Характеристика',
        width: 300,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'Критерий Пирсона',
        width: 150,
        editable: false,
      },
      {
        field: 'secondName',
        headerName: 'Критерий Колмогорова-Смирнова',
        width: 110,
        editable: false,
      },
      {
        field: 'thirdName',
        headerName: 'Критерий Шапиро-Уилка ',
        width: 110,
        editable: false,
      },
    ];

    const columns2 = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'feature',
        headerName: 'Характеристика',
        width: 300,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'Оптимальное теоритическое распределение',
        width: 150,
        editable: false,
      },
      {
        field: 'secondName',
        headerName: 'Значение статистики Колмагорова-Смирнова (норм/опт)',
        width: 110,
        editable: false,
      }
    ];

    const columns3 = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'feature',
        headerName: 'Характеристика',
        width: 300,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'Значение',
        width: 150,
        editable: false,
      },
      {
        field: 'secondName',
        headerName: 'Обеспеченность',
        width: 110,
        editable: false,
      }
    ];
    return [columns1, columns2, columns3]
  }
}
