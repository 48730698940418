import { NavLink } from 'react-router-dom';
import './simple-navbar.css'
import { logoutUser } from '../../services/AuthService';

import logo from './logo.png'

export function SimpleNavbar({isAuth, setIsAuth, setActive}) {
    const enter = isAuth ? 'Выйти' : 'Войти';


    return (
        <div className="simpe-navbar">

<div className="simple-navbar-wrapper">
            <div className="simple-navbar-logo">
                <NavLink to="/"><img className="simple-navbar-logotip" src={logo} alt=""/></NavLink>
            </div>
            <div className="simple-navbar-collection">
                <div className="simple-navbar-collection-item" >
                    <div className="simple-navbar-collection-item-wrapper">
                        <div className="simple-navbar-collection-line" style={{width: '83px'}} />
                        <div className="simple-navbar-collection-name">
                            {enter === 'Войти' ? <NavLink to="/login">{enter}</NavLink> : <NavLink onClick={() => {
                                setIsAuth(false);
                                logoutUser  ();
                            }} to="/login">{enter}</NavLink>}

                        </div>
                    </div>
                </div>
                <div className="simple-navbar-collection-item">
                    <div className="simple-navbar-collection-item-wrapper">
                        <div className="simple-navbar-collection-line" style={{width: '96px'}} />
                        <div className="simple-navbar-collection-name">
                            <NavLink to="/analytics">Анализ</NavLink>
                        </div>
                    </div>
                </div>
                <div className="simple-navbar-collection-item">
                    <div className="simple-navbar-collection-item-wrapper">
                        <div className="simple-navbar-collection-line" style={{width: '110px'}} />
                        <div className="simple-navbar-collection-name">
                            <NavLink onClick={() => window.scrollTo({top: 5050, behavior: 'smooth'})}>Заказать</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
