import {Table} from "./Table";
import './styles/TableWrapper.css'
import {ColumnsService} from "../service/Columns.Service";
import {useMediaQuery} from "react-responsive";
export function TableWrapper({row1, row2, row3}) {
    const columnsService = new ColumnsService();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    let [columns1, columns2, columns3] = isTabletOrMobile ? columnsService.mobileColumns() : columnsService.desktopColumns();
    let [transformedData1, transformedData2, transformedData3] = columnsService.transformingData(row1, row2, row3);

    return (
        <div className="InsideWrapper">

            {row1 !== undefined &&
            <>
            <div className="table-name">
                Оценка характера распределения
            </div>
             <Table columns={columns1} rows={transformedData1} />
            </>}
            <div className="mb-8"></div>

            {row2 !== undefined && <>
            <div className="table-name">
                Поиск оптимального теоретического распределения
            </div>
            <Table columns={columns2} rows={transformedData2} />
            </>}
            <div className="mb-8"></div>

            {row3 !== undefined && 
            <>
                <div className="table-name">
                Обеспеченность нормативных значений механических характеристик
                </div>
                <Table columns={columns3} rows={transformedData3} />
            </>}
        </div>
    )
}
