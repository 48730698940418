import axios from "axios";


export async function registerUser(initials, organization, phone, email, disabled, password) {
    return await axios.post(
        'https://statbim.ru:8000/api/v1/auth/signup',
        {
            organization: organization,
            initials: initials,
            phone: phone,
            email: email,
            disabled: disabled,
            password: password
        }
    )
}

export async function loginUser(email, password) {
    return await axios.post(
        'https://statbim.ru:8000/api/v1/auth/token',
        {
            username: email,
            password: password
        },
        {
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            }
        }
    )
}

export function logoutUser() {
    localStorage.removeItem('token');
}

export function setToken(userToken) {
    localStorage.setItem('token', JSON.stringify(userToken));
}

export function getToken() {
    return localStorage.getItem('token').replace(`"`, '').replace(`"`, '')
}

export function checkToken() {
    return !!localStorage?.getItem('token')
}
