export function Divider({configuration}){

  function changeConfig(config) {
    if (config === 'small') return '1vh'
    if (config === 'standard') return '3vh'
    if (config === 'big') return '6vh'
    if (config === 'extrabig') return '17vh'
    if (config === 'absolute') return '300px'
    return '3vh'
  }

  return (
    <div style={{marginTop: changeConfig(configuration)}} />
  )
}
