import {projects, propertys} from "../utils/helpers/propertyList";
import {Property} from "../components/property/Property";
import {AnimatePresence, motion} from "framer-motion";
import {useState} from "react";

export function Propertys(){
    const [selectedId, setSelectedId] = useState(null)
    const [item, setItem] = useState({
        id: 1,
        title: 'ПРЕДЕЛ ПРОЧНОСТИ (ВРЕМННОЕ СОПРОТИВЛЕНИЕ РАЗРЫВУ)',
        subtitle: 'Механическая характеристика материала, определяется напряжением, соответствующим наибольшему усилию, предшествующему разрыву образца.',
        skills: 'Обозначается  σв, измеряется  в  кгс/мм2 или  Н/ мм2 (Мпа).',
    })
    return (
        // <main className="section">
        //     <div className="container">
        //         <h2 className="title-1">Свойства</h2>
        //         <ul className="projects">
        //             {propertys.map(
        //                 (property,index) => <Property  key={index} title={property.title} index={index}/>
        //             )}
        //         </ul>
        //     </div>
        // </main>
    <div>
        {propertys.map(item => (
            <motion.div layoutId={item.id} onClick={() => {setSelectedId(item.id)}}>
                <motion.h2>{item.title}</motion.h2>
            </motion.div>
        ))}

        <AnimatePresence>
            {selectedId && (
                <motion.div layoutId={selectedId}>
                    <motion.h2>{item.title}</motion.h2>
                    <motion.button onClick={() => setSelectedId(null)} />
                </motion.div>
            )}
        </AnimatePresence>
    </div>
    )
}
