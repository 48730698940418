import {DataGrid} from "@mui/x-data-grid";
import {Box} from "@mui/material";

export function Table({rows, columns}) {
    return (
        <Box>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 8,
                        },
                    },
                }}
                pageSizeOptions={[8]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    )
}
