import "./doughnut.css"

export function Doughnut() {
    return (
        <div className="doughnut__wrapper">
            <div class="doughnut">

            </div>
            <div className="nice">
                <div class="wrap-nice">
                    <div id="check-part-1-nice" class="check-sign-nice"></div>
                    <div id="check-part-2-nice" class="check-sign-nice"></div>
                </div>
            </div>
            <div className="bad">
                <div class="wrap-bad">
                    <div id="check-part-1-bad" class="check-sign-bad"></div>
                    <div id="check-part-2-bad" class="check-sign-bad"></div>
                </div>
            </div>
        </div>
    )
}