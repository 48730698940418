export function Header(){

  return (
    <>
      <span  className="whatIsStatbim__HeaderName">StatBIM - ЦИФРОВАЯ ПЛАТФОРМА ДЛЯ МОНЕТИЗИРОВАНИЯ КАЧЕСТВА СТРОИТЕЛЬНЫХ МАТЕРИАЛОВ И ИЗДЕЛИЙ</span>
      <span className="whatIsStatbim__subName">УСИЛЕНИЕ КОНКУРЕНТНЫХ ПРЕИМУЩЕСТВ ВАШИХ МАТЕРИАЛОВ</span>
      {/* <div>
        <p className="whatIsStatbim__HeaderText">Statbim – это цифровой сервис по обработке результатов лабораторных испытаний строительных материалов.</p>
        <p className="whatIsStatbim__HeaderText">StatBIM формирует профессиональную отчестность.</p>
        <p className="whatIsStatbim__HeaderText">StatBIM гарантирует прозрачность и достоверность информации.</p>
        <p className="whatIsStatbim__HeaderText">StatBIM формирует экономически выгодную среду для всех участников строительного рынка.</p>
      </div> */}
    </>
  )

}
