import {projects} from "../utils/helpers/propertyList";
import {AnimatePresence, motion} from "framer-motion"
import {useState} from "react";
export function Projects(){
    const [selectedId, setSelectedId] = useState(null)
    const [item, setItem] = useState({
        title: 'Бетон',
        subtitle: '',
        body: '',
        id: 1,
    })

    return (
        // <main className="section">
        //         <h2 className="title-1">Материалы</h2>
        //         <ul className="projects">
        //             {projects.map(
        //                 (property,index) => <Property  key={index} title={property.title} index={index}/>
        //             )}
        //         </ul>
        // </main>
        <div>

            {projects.map(item => (
                <motion.div layoutId={item.id} onClick={() => setSelectedId(item.id)}>
                    <motion.h5>{item.subtitle}</motion.h5>
                    <motion.h2>{item.title}</motion.h2>
                </motion.div>
            ))}

            <AnimatePresence>
                {selectedId && (
                    <motion.div layoutId={selectedId}>
                        <motion.h5>{item.subtitle}</motion.h5>
                        <motion.h2>{item.title}</motion.h2>
                        <motion.button onClick={() => setSelectedId(null)} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>


    )
}
