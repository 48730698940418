import './minibubble.css'

export function MiniBubble({text}) {
    return (
        <div className="mini-bubble-wrapper">
            <div className="mini-bubble-text">
                {text}
            </div>
        </div>
    )
}