import "./bigBubble.css"

export function BigBubble({number, header, body, color}){

  return (
    <div className="bigBubble__Wrapper" style={{backgroundColor: `${color}`}}>
      <div className="bigBubble__number">
        {number}
      </div>
      <div className="bigBubble__header">
        {header}
      </div>
      <div className="bigBubble__body">
        {body}
      </div>
    </div>
  )
}
