import './WhatIsStatbim.css'
import {Header} from "./components/Header";
import {Diagram} from "./components/Diagram";

export function WhatIsStatbim(){

    return (
        <div className="whatIsStatbim__Wrapper">
          <Header/>
          <Diagram />



        </div>
    )
}
