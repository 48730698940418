import { DragDropFiles } from "../components/analytics/dropzone/DragDropFiles";
import "../styles/analytics.css"
import {useState} from "react";
import {AnalyticsInfo} from "../components/analytics/AnalyticsInfo";
export function Analytics({isAuth, setIsAuth}) {
  const [starting, setStarting] = useState(true);

  return (

    <div className="py-8 sm:py-4 analytics__background">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 max-h-10xl">
        <h2 className="title_landing">
          Цифровое управление качеством материалов
        </h2>

        <AnalyticsInfo starting={starting} />
        <DragDropFiles isAuth={isAuth} setIsAuth={setIsAuth} setStarting={setStarting} />

      </div>
    </div>
  )
}
