export function PaMListReverse({list = []}) {
    let minusesList = ['Отсутствует']
    let isGood = list.length > minusesList.length;

    if (isGood) minusesList = list;

    return (
        <div className="plusAndMinus__list" style={{height: list.length === 5 ? '750px' : ''}}>
          {minusesList.map((value) => (
            <div className="plusAndMinus__list__wrapper__reverse">

              {!isGood && 
                <div className="plusAndMinus__list__wrapper__icon__reverse">
               <div class="wrap__reverse">
                  <div id="check-part-1-reverse" class="check-sign-reverse"></div>
                  <div id="check-part-2-reverse" class="check-sign-reverse"></div>
                </div>
                </div>
              }

              {isGood && 
              <div className="plusAndMinus__list__wrapper__icon">
                <div class="wrap">
                  <div id="check-part-1" class="check-sign"></div>
                  <div id="check-part-2" class="check-sign"></div>
                </div>
              </div>
              }

              <div className="plusAndMinus__list__wrapper__text__reverse">
                   {value}
              </div>

          </div>
          ))}
        </div>
    )
}
