export const propertys = [
    {
        id: 1,
        title: 'ПРЕДЕЛ ПРОЧНОСТИ (ВРЕМННОЕ СОПРОТИВЛЕНИЕ РАЗРЫВУ)',
        subtitle: 'Механическая характеристика материала, определяется напряжением, соответствующим наибольшему усилию, предшествующему разрыву образца.',
        skills: 'Обозначается  σв, измеряется  в  кгс/мм2 или  Н/ мм2 (Мпа).',
    },
    {
        id: 2,
        title: 'ФИЗИЧЕСКИЙ ПРЕДЕЛ ТЕКУЧЕСТИ ',
        skills: 'Обозначается σт, измеряется в кгс/мм2 или Н/мм2 (Мпа)',
        subtitle: 'Механическая характеристика материала, определяется напряжением, при котором пластические деформации продолжают расти без увеличения нагрузки (наличие площадки текучести).',
    },
    {
        id: 3,
        title: 'УСЛОВНЫЙ ПРЕДЕЛ ТЕКУЧЕСТИ ',
        skills: 'Обозначается σ0,2, измеряется в кгс/мм2 или Н/мм2(Мпа)',
        subtitle: 'Механическая характеристика материала, определяется при отсутствии площадки текучести напряжением, при котором остаточные деформации составляют 0,2 %.',
    },
    {
        id: 4,
        title: 'ПОЛНОЕ ОТНОСИТЕЛЬНОЕ РАВНОМЕРНОЕ УДЛИНЕНИЕ ',
        skills: 'Обозначается δр, измеряется в %.',
        subtitle: 'Механическая характеристика материала после разрыва, определяется как отношение приращения начальной расчётной длины на участке, не включающем место разрыва  к  начальной  расчётной  длине,  выраженной  в  процентах.',
    },
    {
        id: 5,
        title: 'ОТНОСИТЕЛЬНОЕ  УДЛИНЕНИЕ  ПОСЛЕ  РАЗРЫВА',
        skills: 'В зависимости от начальной расчётной длины 5d, 10d или 100 мм(d -диаметр  арматуры) обозначается δ5, δ10 или  δ100, измеряется  в  %.',
        subtitle: 'Механическая характеристика материала после разрыва, определяется как отношение приращения расчётной длины на участке разрыва к начальной расчётной длине, выраженной в процентах.',
    },
    {
        id: 6,
        title: 'МАКСИМАЛЬНОЕ ОТНОСИТЕЛЬНОЕ  УДЛИНЕНИЕ   ПРИ  ПОЛНОЙ   НАГРУЗКЕ  ПРИ  РАСТЯЖЕНИИ',
        skills: 'Обозначается δ max (Agt)  и определяется по формуле δ max= δр + σв/Ен, где Ен – начальный  модуль  упругости. Измеряется  в %.',
        subtitle: 'Механическая характеристика материала после разрыва, определяется удлинение при полной нагрузке при растяжении.',
    },
    {
        id: 7,
        title: 'НАПРЯЖЕНИЕ  (σв или σ0,2)',
        skills: 'Обозначается δ max (Agt)  и определяется по формуле δ max= δр + σв/Ен, где Ен – начальный  модуль  упругости. Измеряется  в %.',
        subtitle: 'Величина , получаемая  делением соответствующего усилия, например, усилия, предшествующего разрушению образца(Рв)  или соответствующего остаточной деформации 0,2% Р0,2, на соответствующую площадь сечения образца, номинальную или фактическую σв= Рв/Fн, σ0,2= Р0,2/ Fн',
    },
];

export const projects = [
    {
        title: 'Бетон',
        subtitle: '',
        body: '',
        id: 1,
    },
    {
        title: 'Арматура',
        subtitle: '',
        body: '',
        id: 2,
    },
];
