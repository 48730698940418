export function CheckboxContacts({setIsChecked, isChecked}) {
    
    const checkHandler = () => {
        setIsChecked(!isChecked);
    }

    return (
    <div>
        <input
        type="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={checkHandler}
        />
        <label htmlFor="checkbox">Я соглашаюсь с политикой конфиденциональности</label>
    </div>
    )
}