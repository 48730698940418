import {toast} from "react-hot-toast";

export class AnalyticService {
   transpose(matrix) {
    return matrix[0].map((col, i) => matrix.map(row => row[i]));
  }

   getResult(result) {
    let values = Object.values(result);
    let keys = Object.keys(result);

    if(values.includes(false)) {
      toast.error('Обратитесь за поддержкой по материалам.')
    }

    return values.map(
      (value, index) =>
        <div className="analytic__row" >
          <div><span className="analytic__row__name">{keys[index]}</span></div>
          <div><span className="analytic__row__value" style={{
            color: value ? 'darkgreen' : 'darkred'
          }}>{value ? 'Норма' : 'Не норма'}</span></div>
        </div>
    )
  }

   getNames() {
    return [ 'Предел текучести',
      'Относительное удлинение при максимальной нагрузке',
      'Относительное удлинение',
      'Относительное равномерное удлинение',
      'Относительная площадь смятия поперечных рёбер',
      'Номинальная масса 1м, кг',
      'Временное сопротивление',
      'σв/σт'
    ]
  }

  getNamesFromObject(object) {
    // console.log(object)
    // console.log(Object.keys(object));
    // console.log(Object.values(object).map(el => Object.values(el)));
    return Object.keys(object);
    // return [ 'Предел текучести',
    //   'Относительное удлинение при максимальной нагрузке',
    //   'Относительное удлинение',
    //   'Относительное равномерное удлинение',
    //   'Относительная площадь смятия поперечных рёбер',
    //   'Номинальная масса 1м, кг',
    //   'Временное сопротивление',
    //   'σв/σт'
    // ]
  }

  getValuesForTable1(object) {
    // console.log(this.transpose(Object.values(object).map(el => Object.values(el))))
    return this.transpose(Object.values(object).map(el => Object.values(el)));
  }

  getValuesForTable2or3(object) {
    console.log(this.transpose(Object.values(object).map(el => Object.values(el))));
    return object
  }
}
