import { useState } from "react";
import "./exampleResults.css"
import { BarChart } from '@mui/x-charts/BarChart';


export function ExampleResults(){

const [goodColor, setGoodColor] = useState('lightGreen');
const [badColor, setBadColor] = useState('darkred');

  return (
    <div>
      <div className="welcomePage__Header">
        Пример обработки результатов
      </div>
      <div className="exampleResults__Content  element-animation">

        <div className="exampleResults-content-text">
          <div className="exampleResults-content-text-item">
            <div className="exampleResults-content-text-item-name">
              Временное сопротивление
            </div>
            <div className="exampleResults-content-text-item-result" style={{color: 'darkgreen'}}>
              Норма
            </div>
          </div>
          <div className="exampleResults-content-text-item">
            <div className="exampleResults-content-text-item-name">
              Предел текучести
            </div>
            <div className="exampleResults-content-text-item-result" style={{color: 'darkred'}}>
              Не норма
            </div>
          </div>
        </div>

        <div className="exampleResults-content-bars  element-animation">
          <BarChart
            xAxis={[{ scaleType: 'band', data: ['Временное сопротивление'] }]}
            series={[
              { data: [1], color: 'lightgreen' },
              { data: [2], color: 'lightgreen' },
              { data: [3], color: 'lightgreen' },
              { data: [3.5], color: 'lightgreen' } ,
              { data: [3], color: 'lightgreen' },
              { data: [2], color: 'lightgreen' },
              { data: [1.5], color: 'lightgreen' },
            ]}
            width={500}
            height={300}
          />

        <BarChart
          xAxis={[{ scaleType: 'band', data: ['Предел текучести'] }]}
          series={[
            { data: [1], color: '#b80f0a' },
            { data: [3], color: '#b80f0a' },
            { data: [1], color: '#b80f0a' },
            { data: [1.5], color: '#b80f0a' } ,
            { data: [4], color: '#b80f0a' },
            { data: [3], color: '#b80f0a' },
            { data: [1.5], color: '#b80f0a' },
          ]}
          width={500}
          height={300}
        />
        </div>



      </div>
    </div>
  )
}
