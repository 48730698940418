import "./welcomePage.css"
import {WhatIsStatbim} from "./whatIsStatbim/WhatIsStatbim";
import {ExampleResults} from "./exampleResults/ExampleResults";
import {Divider} from "./components/Divider/Divider";
import {WhyConsumerNeed} from "./whyConsumerNeed/WhyConsumerNeed";
import {ServiceDevelopment} from "./serviceDevelopment/ServiceDevelopment";
import {PlusAndMinus} from "./plusAndMinus/PlusAndMinus";
import { FinalSteps } from "./finalSteps/FinalSteps";
import { WhyPlantNeed } from "./whyPlantNeed/WhyPlantNeed";
import {useEffect} from "react";

export function WelcomePage({isAuth, setIsAuth}){
  useEffect(() => {
    function onEntry(entry) {
      entry.forEach(change => {
        if (change.isIntersecting) {
          change.target.classList.add('element-show');
        }
      });
    }

    let options = {
      threshold: [0.5] };
    let observer = new IntersectionObserver(onEntry, options);
    let elements = document.querySelectorAll('.element-animation');

    for (let elm of elements) {
      observer.observe(elm);
    }
  }, [])

  function onEntry(entry) {
    entry.forEach(change => {
      if (change.isIntersecting) {
        change.target.classList.add('element-show');
      }
    });
  }

  let options = {
    threshold: [0.5] };
  let observer = new IntersectionObserver(onEntry, options);
  let elements = document.querySelectorAll('.element-animation');

  for (let elm of elements) {
    observer.observe(elm);
  }


  return (
    <div className="welcomePage__Wrapper" >
      <WhatIsStatbim />
      <Divider configuration={'standard'} />
      <WhyPlantNeed />
      <Divider configuration={'standard'} />
      <WhyConsumerNeed />
      <Divider configuration={'standard'} />
      <ServiceDevelopment />
      <Divider configuration={'extrabig'} />
      <Divider configuration={'absolute'} />
      <PlusAndMinus />
      <Divider configuration={'big'} />
      <Divider configuration={'standard'} />
      <ExampleResults/>
      <Divider configuration={'standard'} />
      <FinalSteps />

    </div>
  )
}
