import {toast} from "react-hot-toast";

const url = 'https://statbim.ru:8000/api/v1/file/excel';
const filename = "Шаблон.xlsx";

export function downloadFile() {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(
    );
  }
