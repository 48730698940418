import {DesktopCards} from "./desktopAnalyticsCard/DesktopCards";
import {MobileCards} from "./mobileAnalyticsCard/MobileCards";
import {useMediaQuery} from "react-responsive";

export function AnalyticsInfo({starting}) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            {starting && isDesktopOrLaptop &&  <DesktopCards />}
            {starting && isTabletOrMobile &&  <MobileCards />}
            {starting && <div className="title_form_ready">
                Уже есть готовый шаблон? Загружайте ниже
            </div>}
        </div>
    )
}
