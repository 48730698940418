import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Favicon from "react-favicon";
import App from './App';

import fav from './favicon-32x32.png'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Favicon url={fav} />
    <App />

  </React.StrictMode>
);

