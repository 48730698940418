import { Contacts } from '../../../components/contacts/Contacts';
import { PaMListReverse } from '../plusAndMinus/components/PaMList/PaMListReverse'
import { BlockNumber } from './components/block-number/BlockNubmer';
import './finalSteps.css'
import {Divider} from "../components/Divider/Divider";

export function FinalSteps() {
    const list = [
        'ПРЕДОСТАВЬТЕ РЕЗУЛЬТАТЫ ИСПЫТАНИЙ ПО НАШЕЙ ФОРМЕ',
        'ЗАГРУЗИТЕ НА САЙТ И НАПРАВЬТЕ НАМ ЛЮБЫМ СПОСОБОМ',
        'ДОЖДИТЕСЬ НАШЕЙ ОБРАТНОЙ СВЯЗИ',
        'ПРОВЕДИТЕ МЕРОПРИЯТИЯ ПО УЛУЧШЕНИЮ ПРОИЗВОДСТВА ПОД КОНТРОЛЕМ НАШИХ СПЕЦИАЛИСТОВ',
        'СТАНОВИТЕСЬ ПОЛНОЦЕННЫМ УЧАСТНИКОМ КОМАНДЫ ИЗГОТОВИТЕЛЕЙ И ПОТРЕБИТЕЛЕЙ КАЧЕСТВЕННОЙ ПРОДУКЦИИ',
    ];


    const windowInnerWidth = window.innerWidth;

    return (

      <div>
          <div className="welcomePage__Header">
              Развитие сервиса
          </div>

          <Divider configuration={'small'} />


          <div className="finalSteps-wrapper">
              <div className="finalSteps-stones">
                  <svg width="312" height="234" viewBox="0 0 312 234" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.170744L0.10126 234L251.603 233.568C255.303 233.177 258.929 231.563 262.339 228.819C265.013 226.664 267.515 223.847 269.783 220.411L302.14 159.957C308.571 148.57 312.154 132.507 311.995 115.779C311.836 99.7265 308.237 84.4867 302.025 73.6257L273.352 18.925C270.361 12.9426 266.762 8.18373 262.758 4.92959C258.598 1.54008 254.088 -0.141654 249.551 0.00933863L0 0.170744Z" fill="#B3B6BA" fill-opacity="0.301956"/>
                  </svg>

                  <svg width="312" height="234" viewBox="0 0 312 234" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.170744L0.10126 234L251.603 233.568C255.303 233.177 258.929 231.563 262.339 228.819C265.013 226.664 267.515 223.847 269.783 220.411L302.14 159.957C308.571 148.57 312.154 132.507 311.995 115.779C311.836 99.7265 308.237 84.4867 302.025 73.6257L273.352 18.925C270.361 12.9426 266.762 8.18373 262.758 4.92959C258.598 1.54008 254.088 -0.141654 249.551 0.00933863L0 0.170744Z" fill="#B3B6BA" fill-opacity="0.301956"/>
                  </svg>
              </div>
              {<>
                  <div className="finalSteps-numbers element-animation">
                      <BlockNumber color={'rgba(193, 197, 202, 1)'} number={'1'} />
                      <BlockNumber color={'rgba(124, 202, 246, 1)'} number={'2'} />
                      <BlockNumber color={'rgba(179, 182, 186, 1)'} number={'3'} />
                      <BlockNumber color={'rgba(55, 163, 224, 1)'} number={'4'} />
                      <BlockNumber color={'rgba(101, 106, 112, 1)'} number={'5'} />
                  </div>
                  <div className="finalSteps-list-wrapper element-animation">
                      <div className="finalSteps-list">
                          <PaMListReverse list={list} />
                      </div>
                  </div>
              </>
              }
              <div className="finalSteps-contacts">
                  <Contacts option={false} />
              </div>
          </div>
      </div>

    )
}
