import { useEffect, useState } from 'react';
import './contacts.css'
import {toast, Toaster} from "react-hot-toast";
import InputMask from "react-input-mask";
import { CheckboxContacts } from './components/checkbox/CheckboxContacts';

export function Contacts() {
    const handleScroll = () => {
        if (window.scrollY > 5000) setIsActive(true);
        if (window.scrollY < 5000) setIsActive(false);
    };
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [])
    
    const [isActive, setIsActive] = useState(false);

    const handleSubmit = async () => {
        let message = name + ' ' + phone;
        if (!!name && !!phone && isChecked) {
            let response = await fetch(`https://api.telegram.org/bot5686469491:AAGuWYRIfWvlPKU5i2hc3BBc-4JLX_KvaWM/sendMessage?chat_id=194897819&text={${message}}`,
            {
                method: 'POST',
                body: JSON.stringify(message),
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200){
                toast.success('Заявка успешно отправлена!')
            } else{
                toast.error('Ошибка отправки. Повторите позднее.')
            }
        } else {
            toast.error('Заполните все поля.')
        }

    }

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isChecked, setIsChecked] = useState(false);


    const handleClick = (e) => {
        e.preventDefault();
        setIsActive(!isActive);
    }

    return (
        <div className={!isActive ? 'contacts-wrapper' : 'contacts-wrapper contacts-wrapper_active'} >
            <div className="contacts">

                <div className="contacts-line" onClick={handleClick} />

                <div className="contacts-form">
                    <div className="contacts-form-name">
                        Оставьте контакты, мы свяжемся и ответим на вопросы
                    </div>
                    <form>
                        <div className="contacts-form-inputs">
                            <input
                                className='contacts-form-input'
                                placeholder="Имя"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                required                               
                            />

                            <InputMask 
                                className="contacts-form-input"
                                placeholder="+7 (XXX) XXX-XX-XX"
                                mask="+7 (999) 999-99-99" 
                                onChange={e => setPhone(e.target.value)} 
                                value={phone}  
                                required                       
                            />

                        </div>
                        <div className="contacts-form-check">
                            <CheckboxContacts setIsChecked={setIsChecked} isChecked={isChecked} />
                        </div>
                        <div className="contacts-form-button-accept" onClick={handleSubmit}>
                            <input type="submit" value="Отправить заявку" />
                        </div>
                    </form>
                </div>
            </div>

        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
                background: '#363636',
                color: '#fff',
            },

            // Default options for specific types
            success: {
                duration: 3000,
                theme: {
                primary: 'green',
                secondary: 'black',
                },
            },
            }}
        />


        </div>
    )
}