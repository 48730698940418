import "./whyPlantNeed.css"
import {BigBubble} from "../whyPlantNeed/components/bigBubble/BigBubble";
import {Divider} from "../components/Divider/Divider";
import { MiniBubble } from "./components/miniBubble/MiniBubble";


export function WhyPlantNeed(){

  return (
    <div className="whyConsumerNeed__Wrapper">
      <div className="welcomePage__Header">
        Зачем нужен StatBIM заводу
      </div>

      <Divider configuration={'small'} />

      <div className="whyConsumerNeed__TextBlock">
        <div className="whyConsumerNeed__TextBlock__ObjectCircle">

          <div className="whyConsumerNeed__TextBlock__ObjectCircle__BlackCircle">
            <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 41.6771C0 18.6615 18.6615 0 41.6823 0C64.6979 0 83.3594 18.6615 83.3594 41.6771C83.3594 64.6979 64.6979 83.3594 41.6823 83.3594C18.6615 83.3594 0 64.6979 0 41.6771Z" fill="#232422"/>
            </svg>
          </div>

          <div className="whyConsumerNeed__TextBlock__ObjectCircle__Text">
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.599 0.28125C9.28654 0.28125 0.119873 9.4375 0.119873 20.7448C0.119873 32.0521 9.28654 41.2396 20.599 41.2396C31.9167 41.2396 41.0834 32.0521 41.0834 20.7448C41.0678 9.44792 31.9115 0.291667 20.599 0.28125ZM19.4011 4.57292C20.4792 4.49479 21.5886 4.52604 22.698 4.67188C29.9949 5.625 35.7345 11.3594 36.6876 18.651H36.1355C34.9636 18.651 34.0157 19.5781 34.0157 20.7448C34.0157 21.9167 34.9636 22.8698 36.1355 22.8698H36.6876C35.7188 30.1406 30.0001 35.875 22.724 36.8438V36.2969C22.724 35.125 21.7709 34.1771 20.599 34.1771C19.4324 34.1771 18.4792 35.125 18.4792 36.2969V36.8438C11.198 35.875 5.46362 30.1406 4.48967 22.8698H5.06779C6.23966 22.8698 7.16154 21.9167 7.16154 20.7448C7.16154 19.5781 6.23966 18.651 5.06779 18.651H4.48967C5.5105 10.875 11.8751 5.11458 19.4011 4.57292ZM20.599 7.34375C19.4324 7.34375 18.4792 8.29688 18.4792 9.46354V18.651H17.7813C16.6095 18.651 15.6563 19.5781 15.6563 20.7448C15.6563 21.9167 16.6095 22.8698 17.7813 22.8698H18.4792V23.5938C18.4792 24.7604 19.4324 25.6875 20.599 25.6875C21.7709 25.6875 22.724 24.7604 22.724 23.5938V22.8698H29.0678C30.2397 22.8698 31.1928 21.9167 31.1928 20.7448C31.1928 19.5781 30.2397 18.651 29.0678 18.651H22.724V9.46354C22.724 8.29688 21.7709 7.34375 20.599 7.34375Z" fill="#EFF0EF"/>
            </svg>
          </div>

        </div>
        <div className="whyConsumerNeed__TextBlock__BodyText">
            Служит основой для контроля производства, выявляет конкурентные преимущества и снижает издержки производства
        </div>
      </div>

      <Divider configuration={'standard'} />

      <div className="whyPlantNeed__Bubbles  element-animation">
        <div className="whyPlantNeed__Bubbles__Forward">
          <BigBubble number={1} body={"Образцы переданы в лабораторию при заводе, получен сертификат соответствия (равные условия со всеми)"} header={"ЗАВОД ВЫПУСТИЛ ПРОДУКЦИЮ"} color={"#CEE0F8"} />
          <BigBubble number={2} body={"Получен отчет по стабильности показателей продукции"} header={"ЛАБОРАТОРИЯ ПЕРЕДАЛА МАССИВ ДАННЫХ В STATBIM"} color={"#D4D7DA"} />
          <BigBubble number={3} body={"Разработаны мероприятия для увеличения конкурентных преимуществ продукции (при необходимости)"} header={"АНАЛИЗ ОТЧЕТА STATBIM"} color={"#9FC2F0"} />
        </div>


        <div className="whyPlantNeed__Bubbles__Reverse">
            <div className="whyPlantNeed__Bubbles__Reverse_5">
                <BigBubble number={5} body={"Реализация продукици необходимого качества при оптимальных затратах"} header={"ПОЛУЧЕНИЕ ПРИБЫЛИ"} color={"#CEE0F8"} />
            </div>
            <div className="whyPlantNeed__Bubbles__Reverse_4">
                <BigBubble
                number={4}
                body={"Документ отражает фактические характеристики по стабильности свойств продукции"}
                header={`ПОЛУЧЕН СЕРТИФИКАТ НИЦ`}
                color={"#C2C5C9"} />
            </div>

            <div className="whyPlantNeed__Bubbles__Reverse_mini-1">
                <MiniBubble text={'Расширение использования продукции в проектах'} />
            </div>
            <div className="whyPlantNeed__Bubbles__Reverse_mini-2">
                <MiniBubble text={'Снижение количества испытаний контрольных образцов'} />
            </div>
            <div className="whyPlantNeed__Bubbles__Reverse_mini-3">
                <MiniBubble text={'Включение в реестр НИЦ'} />
            </div>
            <div className="whyPlantNeed__Bubbles__Reverse_mini-4">
                <MiniBubble text={'Переработка типовых серий с Вашей продукцией'} />
            </div>

        </div>
      </div>
    </div>
  )
}
