import './miniDoughnut.css'

export function MiniDoughnut() {
    return (
        <div className="mini-doughnut-wrapper">
            <div class="mini-doughnut" />
            <div className="mini-nice">
                <div class="mini-wrap-nice">
                    <div id="mini-check-part-1-nice" class="mini-check-sign-nice" />
                    <div id="mini-check-part-2-nice" class="mini-check-sign-nice" />
                </div>
            </div>
            <div className="mini-bad">
                <div class="mini-wrap-bad">
                    <div id="mini-check-part-1-bad" class="check-sign-bad" />
                    <div id="mini-check-part-2-bad" class="check-sign-bad" />
                </div>
            </div>
        </div>
    )
}