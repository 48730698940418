import { useState, useRef } from "react";
import {getToken, logoutUser} from "../../../services/AuthService";
import "../../../styles/radio.css"
import "./style.css"
import "../../../styles/files.css"
import "../../../styles/graphs.css"
import axios from "axios";
import Plotly from 'plotly.js-dist';
import {TableWrapper} from "../TableAnalytics/TableWrapper";
import {useMediaQuery} from "react-responsive";
import {toast, Toaster} from "react-hot-toast";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBrain, faUpload} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {AnalyticService} from "../service/Analytic.service";



export const  DragDropFiles = ({isAuth, setIsAuth, setStarting}) => {

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const transpose = matrix => matrix[0].map((col, i) => matrix.map(row => row[i]));
  const [file, setFiles] = useState(null)
  let resData = Object, graphData = Object;
  let arr, onlyWord;
  const [grades, setGrades] = useState([])
  const [sizes, setSizes] = useState([])
  const [check, setCheck] = useState(false)
  const [numGrade, setNumGrade] = useState(-1)
  const [numSize, setNumSize] = useState(-1)
  const inputRef = useRef();
  const [waiting, setWaiting] = useState(false)
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files)
    setStarting(false);
  };

  const handleUpload = async () => {
    if(isAuth) {
      try {
        setStarting(false);
        setWaiting(true)
        const token = getToken();
        const res = await axios.post('https://statbim.ru:8000/api/v1/analysis/selectors/',
            {file: file?.[0] }
            , {
              headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
              },
            })
        resData = res?.data;
        setGrades(Object.values(resData)[0])
        setSizes(Object.values(resData)[1])
        setWaiting(false);
      } catch (e) {
        if(e?.response?.status === 401) {
          toast.error('Сессия истекла. Авторизуйтесь снова.');
          setWaiting(false);
          setIsAuth(false);
          setTimeout(logoutUser, 3000)
        } else {
          toast.error('Непредвиденная ошибка');
        }
      }
    } else {
      toast.error('Для начала работы необходимо авторизоваться. Вы попадете на страницу авторизации.');
      setTimeout(
          () => navigate("/login"), 2950
      )
    }


  };

  let y, z, d;

  const [firstTableRow, setFirstTableRow] = useState();
  const [secondTableRow, setSecondTableRow] = useState();
  const [thirdTableRow, setThirdTableRow] = useState();
  const [result, setResult] = useState(null);
  const analyticService = new AnalyticService();


 
  const handleSelectors = async () => {
    setWaiting(true);
    try {
      const token = getToken();
      const res = await axios.post(`https://statbim.ru:8000/api/v1/analysis/stats`,
        {file: file?.[0] }
        , {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
          },
          params: {
            size: `${numSize.toString()}`,
            grade: `${numGrade.toString()}`
          }
        })
      graphData = res?.data;
      setCheck(true)
      arr = Array.from(Object.values(graphData))
      let hists = (Object.values(Object.values(arr[0]))[0])
      let table1 = Object.values(Object.values(Object.values(arr[1]))[0])

      onlyWord = Object.values(Object.values(arr[1]))[1]
      //let oihfgjd = onlyWord.toString().replaceAll(`"`, ``).replace(`{`, ``).replace(`}`, ``).replaceAll(`Н/мм²,`, ``).replaceAll(`%,`, ``)
      let table23 = (Object.values(Object.values(arr[2])))

      const colors = ['#636EFA', '#EF553B', '#00CC96', '#AB63FA', '#FFA15A', '#19D3F3', '#FF6692', '#B6E880', '#FF97FF', '#FECB52']
      const names = analyticService.getNamesFromObject(Object.values(arr[1])[0]);

      //table2
      y = [names]
      let help = []
      //table3
      z = [names]
      let help_z = []
      //table4
      d = [names]
      let help_d = []

      for (let i = 0; i < 8; i++) {
        let x = Array.from(  Object.values( Object.values(hists)    )     )[i];

        let alpha = Object.values(Array.from(  Object.values( Object.values(Object.values(Array.from(  Object.values( Object.values(table23)    )     )[2]))    )     )[i])
        let betta = Object.values(Array.from(  Object.values( Object.values(Object.values(Array.from(  Object.values( Object.values(table23)    )     )[3]))    )     )[i])
        //its block for histograms
        let layout = {
          title: (Object.keys(hists))[i],
          autosize: true,
          height: 300,
          width: isTabletOrMobile ? 300 : 650,
          margin: {
            l: 40,
            r: 0,
          },
          automargin: true,
        };


        x = (Object.values(x)[0]);

        let trace = {
          x: x,
          type: 'histogram',
          histnorm: 'probability density',
          name: (Object.keys(hists))[i],
          marker: {
            color: colors[i]
          }
        };

        let trace_norm = {
          x: alpha[0],
          y: alpha[1],
          type: 'scatter',
          name: "Нормальное распределение",
          marker: {
            color: "black"
          }
        }

        let trace_opt = {
          x: betta[0],
          y: betta[1],
          type: 'scatter',
          name: "Оптимальное распределение",
          marker: {
            color: 'darkgreen'
          }
        }

        let data = [trace, trace_norm, trace_opt];
        Plotly.newPlot(`hist_${i}`, data, layout);

      }

      for (let j = 0; j < 8; j++){
        help.push( Object.values(Array.from(  Object.values( Object.values(table1)    )     )[j]))
        help_z.push(Object.values(Array.from(  Object.values( Object.values(Object.values(Array.from(  Object.values( Object.values(table23)    )     )[0]))    )     )[j]))
        help_d.push(Object.values(Array.from(  Object.values( Object.values(Object.values(Array.from(  Object.values( Object.values(table23)    )     )[1]))    )     )[j]))
      }
      
      // y.push(...transpose(help))
      y.push(...analyticService.getValuesForTable1(Object.values(arr[1])[0]))
      z.push(...transpose(help_z))
      d.push(...transpose(help_d));
      

      setFirstTableRow(y);
      setSecondTableRow(z);
      setThirdTableRow(d);
      setResult(analyticService.getResult(onlyWord));
      setShow(true);
    } catch (e) {
      if(e?.response?.status === 401) {
        toast.error('Сессия истекла. Авторизуйтесь снова.');
        setWaiting(false);
        setIsAuth(false);
        setTimeout(logoutUser, 3000)
      } else {
        toast.error('Непредвиденная ошибка');
      }
    }
  }


  if((grades?.length > 0) && (sizes?.length > 0) ) return (
    <>
      {!check && <div className="form__radio__wrapper">
        <div className="form__radio__wrapper__group">
          {
            grades.map(
                (grade, index) =>
                    <motion.div
                        className="group__item"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={
                          () => {
                            setNumGrade(grade);
                          }
                        }
                    >
                      {grade}
                    </motion.div>
            )
          }
        </div>
        <div className="form__radio__wrapper__group">
          {(numGrade !== -1) && sizes.map(
              (size, index) =>
                  <motion.div
                      className="group__item"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                      setNumSize(size);
                      }
                  }>
                    {size}
                  </motion.div>
          )}

        </div>
        {(numSize !== -1) && !waiting &&
            <div onClick={handleSelectors} className="analyses__button">
              <span className="analyses__button__span">Отправить</span>
              <FontAwesomeIcon icon={faUpload} className="analyses__button__icon" />
            </div>
        }
        {(numSize !== -1) && waiting &&
            <div onClick={handleSelectors} className="analyses__button">
              <span className="analyses__button__span">Ожидайте</span>
              <FontAwesomeIcon icon={faBrain} className="analyses__button__icon"/>
            </div>
        }
      </div>}


      {show && <div className="info_wrapper">
        {result}
      </div> }



      <div className="plot_wrapper">
        <div id='hist_0' className="plot_hist"></div>
        <div id='hist_1' className="plot_hist"></div>
        <div id='hist_2' className="plot_hist"></div>
        <div id='hist_3' className="plot_hist"></div>
        <div id='hist_4' className="plot_hist"></div>
        <div id='hist_5' className="plot_hist"></div>
        <div id='hist_6' className="plot_hist"></div>
        <div id='hist_7' className="plot_hist"></div>
      </div>

      <div className="mb-8"></div>

      <div className="table_wrapper">
        <TableWrapper row1={firstTableRow} row2={secondTableRow} row3={thirdTableRow} />
      </div>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </>


  )

  if (file && (grades?.length === 0)) return (
    <div className="uploads">
      <div className="upload_continue">
          <ul className="files_list">
              {Array.from(file).map((file, idx) => <li className="files_list_item" key={idx}>{file.name}</li> )}
          </ul>
          <div className="actions">
              <button className="cancel" onClick={() => {setFiles(null); setStarting(true);}}>Отмена</button>
              {!waiting && <button className="upload" onClick={handleUpload}>Загрузить</button> }
              {waiting && <button className="upload" >Ожидайте...</button> }
          </div>
      </div>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  )

  return (
    <>
        <div
            className="dropzone"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
          <h1>Форма для загрузки</h1>
          <h1>или</h1>
          <input
            type="file"
            multiple
            onChange={(event) => setFiles(event.target.files)}
            hidden
            ref={inputRef}
          />
          <button className="bg-gray-300" onClick={() => inputRef.current.click()}>загрузка вручную</button>
        </div>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </>
  );
};
