import {useState} from "react";
import {loginUser, setToken} from "../services/AuthService";
import {useNavigate} from "react-router-dom";
import "../styles/login.css";
import {toast, Toaster} from "react-hot-toast";

export function Login({setIsAuth}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()


    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = loginUser(email, password);
        token
            .then((data) =>
                {
                    setToken(data?.data?.access_token)
                    setIsAuth(true)
                    navigate(`/`)
                })
            .catch((error) => {
                if(error?.response?.status === 401) {
                    toast.error('Такого пользователя нет. Проверьте введенные данные.')
                } else {
                    toast.error('Непредвиденная ошибка. Повторите попытку позже.')
                }
                
            });
    }

    return (
        <>
            <div className="wrapper__login">
                <div className="container__login">
                    <div className="title__login">Авторизация</div>
                    <div className="content__login">
                        <form onSubmit={handleSubmit}>
                            <div className="user-details__login">
                                <div className="input-box__login">
                                    <span className="details__login">Почта</span>
                                    <input
                                        type="email"
                                        placeholder="Введите адрес электронной почты"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        required />
                                </div>
                                <div className="input-box__login">
                                    <span className="details__login">Пароль</span>
                                    <input
                                        type="password"
                                        placeholder="Введите пароль"
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        required />
                                </div>
                            </div>
                                <div className="button__login">
                                    <input type="submit" value="Войти" />
                                </div>
                            </form>
                        <div className="input-box__login">
                            <input value="Зарегистрироваться" type="submit" onClick={() => {
                                navigate(`/register`)
                            }} />
                        </div>
                        </div>
                    </div>
                </div>
                <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />

        </>
    )
}
