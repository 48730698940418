import '../styles/agreement.css'
export function Agreement() {
    return (
        <div className="wrapper__agreement">
            <div className="container__agreement">
                <h1 className="header__agreement">Правила обработки персональных данных</h1>
            </div>
            <h1>Основные понятия</h1>
            <p><strong>Сайт</strong> — statbim.ru</p>
            <p><strong>Пользователь</strong> — посетитель Сайта.</p>
            <p><strong>НИИЖБ</strong> — НАУЧНО-ИССЛЕДОВАТЕЛЬСКИЙ, ПРОЕКТНО-КОНСТРУКТОРСКИЙ И ТЕХНОЛОГИЧЕСКИЙ ИНСТИТУТ БЕТОНА И ЖЕЛЕЗОБЕТОНА ИМ. А.А.ГВОЗДЕВА. АО НИЦ Строительство (ИНН 5042109739 КПП 504201001 ОГРН: 1095042005255 адрес: 109428, г. Москва, 2-я Институтская ул, д.6).</p>
            <p><strong>Персональные данные</strong> — любая информация, относящаяся к определенному физическому лицу.</p>
            <p><strong>Соглашение</strong></p>
            <p>
                НИИЖБ обязуется обеспечить конфиденциальность и сохранность персональных данных, полученных от Пользователя в соответствии с ФЗ-152 «О персональных данных». НИИЖБ вправе использовать технологию «cookies» и Мета-данных. Cookies и Мета-данные не содержат конфиденциальную информацию. Пользователь настоящим дает согласие на сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передачу, включая трансграничную передачу), обезличивание, блокирование, уничтожение предоставленных cookies и Мета-данных, в том числе третьими лицами, включая системы аналитики Google Analytics и Яндекс.Метрика для целей формирования статистики и оптимизации рекламных сообщений. При регистрации на Сайте Пользователь предоставляет следующую информацию: фамилия, имя, отчество, телефон, адрес электронной почты. Предоставляя свои персональные данные.

                Также НИИЖБ вправе использовать персональные данные Пользователя для продвижения Услуг НИИЖБ и Услуг компаний партнеров, проведения электронных опросов, контроля результатов маркетинговых акций, клиентской поддержки, проведения розыгрышей призов среди Пользователей, контроля удовлетворенности Пользователя, а также качества услуг, оказываемых НИИЖБ.

                НИИЖБ вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:

                передача необходима в рамках оказания услуг Пользователю;
                передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;
                в целях обеспечения возможности защиты прав и законных интересов НИИЖБ или в случаях, когда Пользователь нарушает требования действующего законодательства.
                Пользователь вправе отказаться от получения рекламной и другой информации без объяснения причин отказа путем информирования НИИЖБ о своем отказе посредством направления сообщения, составленного в свободной форме и отправленного на электронный адрес НИИЖБ: niizhb@cstroy.ru
            </p>
        </div>
    )
}
