import Download from "../../../img/cards/download.png";
import Type from "../../../img/cards/type.png";
import Load from "../../../img/cards/load.png";
import {motion} from "framer-motion";
import {AnalyticsCard} from "../analyticsCard/AnalyticsCard";

export function DesktopCards() {
    return (
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <motion.div className="card_wrapper" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <AnalyticsCard type={"download"} src={Download} title={"Скачивайте"} text={"наш шаблон, чтобы заполнить его верно"}/>
            </motion.div>
            <motion.div className="card_wrapper" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <AnalyticsCard src={Type} title={"Заполняйте"} text={"шаблон по образцу для анализа материала"}/>
            </motion.div>
            <motion.div className="card_wrapper" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <AnalyticsCard type={"load"} src={Load} title={"Загружайте"} text={"его на сервер для аналитики"}/>
            </motion.div>
        </div>
    )
}
